import { Board } from '../Entities/Board';
import { SearchDto } from './common/searchDto';

/**
 * Not doing search by dates for filters
 */
export class SearchBoardDto extends SearchDto<Board, never> {
  title?: string;
  meetingDate?: Date;
  startDate?: Date;
  endDate?: Date;
  isArchived?: boolean;
  includeArchived?: boolean;
  isClosed?: boolean;
}
