
import { Component, Prop, Vue } from 'vue-property-decorator';
import LegacyTableComponent from '@/models/Table/Legacy/LegacyTableComponent.vue';
import { Board } from '@/models/Entities/Board';
import { BoardDataProvider } from '@/components/Table/Board/BoardDataProvider';
import { boardBoardTableConfigBuilder } from '@/components/Table/Board/BoardTableConfigBuilder';
import { BoardTableData } from '@/components/Table/Board/BoardTableData';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import { SearchBoardDto } from '@/models/Dtos/searchBoardDto';
import AsyncTableComponent from '@/components/Table/AsyncTableComponent.vue';

@Component<A3BoardCollectionPage>({
  components: {
    LegacyTableComponent,
    AsyncTableComponent
  }
})
export default class A3BoardCollectionPage extends Vue {
  @Prop()
  boards!: Board[];

  boardSearchService = new TableSearchService<
    BoardTableData,
    Board,
    never,
    SearchBoardDto
  >(
    this.$store,
    new BoardDataProvider(this.$store),
    new SearchBoardDto(),
    { defaultSortProperty: 'meetingDate' },
    boardBoardTableConfigBuilder,
    { filters: [], quickFilters: [] }
  );

  mounted() {
    this.boardSearchService.queryData();
  }
}
